import moment from 'moment'
import { refusedReasonArray } from '../../adherenceDashboardUtils'

const OVERRIDE_STATUS_MAP = {
  CREATE_CONFIRMED: 'OVERRIDE_CONFIRMED',
  CREATE_REFUSED: 'OVERRIDE_REFUSED',
}

const buildDateAndTimeWithTimeZone = ({ date, time, timezone }) => {
  const dateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(time, 'hh:mm A').format('HH:mm:ss')}`
  const dateTimeInTimeZone = moment(dateTimeString).tz(timezone, true)
  return dateTimeInTimeZone.toISOString()
}

const createConsumptionPayload = ({
  formData,
  isAddingPrn,
  isOverriding,
  selectedCellTime,
  organization: { timezone, patientLabelSingular },
  dose,
  doseSig,
  patientId,
}) => {
  const {
    administeredBy, status, dateTime, time, prnReason, reason, note,
  } = formData

  const basePayload = {
    code: isOverriding ? OVERRIDE_STATUS_MAP[status] : status,
    subject_id: administeredBy.id,
    prn: true,
    prn_reason: prnReason,
    reason: refusedReasonArray(patientLabelSingular)
      .find((r) => r.value === reason)?.label,
    note,
    patientId,
  }

  if (isAddingPrn) {
    return {
      consumption: {
        ...basePayload,
        confirmed_at: buildDateAndTimeWithTimeZone(
          { date: selectedCellTime, time, timezone },
        ),
        doseId: dose.id,
        doseSigId: doseSig?.id,
      },
    }
  }

  if (isOverriding) {
    return {
      ...basePayload,
      timestamp: buildDateAndTimeWithTimeZone(
        { date: dateTime, time: dateTime, timezone },
      ),
    }
  }

  return null
}

const convertToOrgTimezone = (date, timezone) => moment.tz(date, timezone).toISOString()

export {
  createConsumptionPayload,
  buildDateAndTimeWithTimeZone,
  convertToOrgTimezone,
}

import React, {
  memo, useEffect, useRef, useState,
} from 'react'
import DateTable from '@components/display/DateTable/DateTable'
import { OverlayPanel } from 'primereact/overlaypanel'
import { get, isEmpty, startCase } from 'lodash'
import { useVitalsDashboardQuery } from '@hooks/patients/vitalDashboard'
import {
  conditionChecksByStatus, conditionCheckValueDisplay, formatDashboardItems, vitalsDashboardColumns,
} from './VitalDashboardUtils'
import StatusOverlay from './StatusOverlay'
import CompletedTag from './StatusTag/CompletedTag'
import SkippedTag from './StatusTag/SkippedTag'
import MissedTag from './StatusTag/MissedTag'

const VitalsDashboard = memo(({
  dateRange, timezone, patientId,
}) => {
  const statusOverlay = useRef()
  const [seletecConditionChecks, setSelectedConditionChecks] = useState()
  const [formattedDashboardItems, setFormatedDashboardItems] = useState()
  const statusMessagesRef = useRef()
  const {
    data: vitalsDashboard,
    isLoading,
  } = useVitalsDashboardQuery(patientId, dateRange, statusMessagesRef)

  useEffect(() => {
    setFormatedDashboardItems(formatDashboardItems(vitalsDashboard?.vitalsDashboardItems, timezone))
  }, [vitalsDashboard, timezone])

  const onSelectConditionChecks = (e, conditionChecks) => {
    setSelectedConditionChecks(conditionChecks)
    if (statusOverlay.current) {
      statusOverlay.current.hide()
      requestAnimationFrame(() => {
        statusOverlay.current.show(e)
      })
    } else {
      statusOverlay.current.show(e)
    }
  }

  const measurementTypeTemplate = (rowData) => (
    <div className="flex flex-column gap-1 align-items-start text-left">
      <span>{startCase(rowData.measurementType)}</span>
    </div>
  )

  const conditionCheckTemplate = (rowData, column) => {
    const conditionChecks = get(rowData, column.field)
    if (isEmpty(conditionChecks)) return null
    const byStatus = conditionChecksByStatus(conditionChecks)
    return (
      <div className="flex flex-column gap-1 align-items-center justify-content-center">
        {byStatus.completed?.map((conditionCheck) => (
          <CompletedTag
            key={conditionCheck.id}
            onClick={(e) => onSelectConditionChecks(e, [conditionCheck])}
            value={conditionCheckValueDisplay(conditionCheck)}
          />
        ))}
        {
          byStatus.skipped?.length > 0 && (
            <SkippedTag
              skipCount={byStatus.skipped.length}
              onClick={(e) => onSelectConditionChecks(e, byStatus.skipped)}
            />
          )
        }
        {
          byStatus.missed?.length > 0 && (
            <MissedTag
              missedCount={byStatus.missed.length}
              onClick={(e) => onSelectConditionChecks(e, byStatus.missed)}
              className="bg-red-500"
            />
          )
        }
      </div>
    )
  }

  return (
    <div>
      <OverlayPanel ref={statusOverlay} className="dose-overlay">
        <StatusOverlay
          conditionChecks={seletecConditionChecks}
          timezone={timezone}
        />
      </OverlayPanel>
      <DateTable
        columns={vitalsDashboardColumns({
          dateRange,
          measurementTypeTemplate,
          conditionCheckTemplate,
        })}
        data={formattedDashboardItems}
        isLoading={isLoading}
        tableClassName="dose-consumptions"
        emptyMessage="No vitals available"
        className="schedule-mar-view"
        rowGroupMode="rowspan"
        groupRowsBy={['treatment.id', 'id']}
        sortMode="single"
      />
    </div>
  )
})

export default VitalsDashboard

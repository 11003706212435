// eslint-disable-next-line import/prefer-default-export
export const statusLabels = {
  CONFIRMED: 'Given',
  OVERRIDE_CONFIRMED: 'Given',
  OVERRIDE_MISSED: 'Missed',
  OVERRIDE_PENDING: 'Pending',
  OVERRIDE_LOA: 'Leave of Absence',
  OVERRIDE_REFUSED: 'Refused',
  PATIENT_REFUSED: 'Refused',
  CREATE_CONFIRMED: 'Given',
  CREATE_MISSED: 'Missed',
  CREATE_REFUSED: 'Refused',
  BARCODE_SCANNED: 'Barcode Scanned',
}

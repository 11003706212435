import React from 'react'
import moment from 'moment-timezone'
import { groupBy, isEmpty, map } from 'lodash'
import ConsumptionTag from './ConsumptionTag'
import EmptyCell from '../EmptyCell'

function StatusIndicator({
  consumptions,
  doseSig,
  cellTime,
  isAdmin,
  onEmptyCellClick,
  onConsumptionsClick,
}) {
  if (isEmpty(consumptions)) {
    if (cellTime.isAfter(moment())) return null
    if (!isAdmin) return null
    return (
      <EmptyCell onClick={() => onEmptyCellClick(doseSig, cellTime)} />
    )
  }

  return (
    <div
      className="flex flex-column justify-content-center align-items-center gap-1 w-full h-full cursor-pointer"
    >
      {map(groupBy(consumptions, 'status'), (consumptionsByStatus, status) => (
        <ConsumptionTag
          onClick={onConsumptionsClick}
          key={status}
          type={status}
          count={consumptionsByStatus.length}
        />
      ))}
      <span className="flex flex-row w-full justify-content-center align-content-center cursor-pointer">
        {isAdmin && (
          <i
            className="pi pi-plus-circle text-400"
            style={{ fontSize: '1rem' }}
            onClick={() => onEmptyCellClick(doseSig, cellTime)}
          />
        )}
      </span>
    </div>
  )
}

export default StatusIndicator

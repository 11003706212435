import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Tag } from 'primereact/tag'
import { Column } from 'primereact/column'
import { formatDoseAmount } from '@components/clientDoses/doseUtils'
import {
  every, get, isEmpty, map,
} from 'lodash'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages'
import { Dropdown } from 'primereact/dropdown'
import { timeTemplate, titleCase, dateTimeTemplateFromMoment } from '@services/utils'
import moment from 'moment'
import { InputText } from 'primereact/inputtext'
import { useDoseSigsQuery, useUpdateDoseSigMutation } from '@services/hooks/doseSigsHooks'
import { momentFormats, momentFromIso8601Str } from '@services/utils/moment'
import { useSubmitMedicationsMutation } from '@hooks/hrst'
import { allowedHrstPurposes } from '../../../features/Patients/config'
import { manuallyEnteredFrequency } from '../../clientDoses/config'

function FrequencyTemplate({
  frequency, hl7ScheduleCodeFrequency, newFrequency, setNewFrequency, isEditing,
}) {
  if (hl7ScheduleCodeFrequency) return <span>{hl7ScheduleCodeFrequency}</span>

  if (!isEditing) {
    if (!frequency) {
      return (
        <div className="flex flex-row gap-1">
          <i className="pi pi-exclamation-triangle text-orange-500" />
          <span className="text-xs">Click edit to set frequency</span>
        </div>
      )
    }
    return (
      <span style={{ wordBreak: 'break-all', minWidth: '120px' }}>{frequency}</span>
    )
  }

  return (
    <Dropdown
      className="w-12"
      value={newFrequency}
      options={map(manuallyEnteredFrequency, (value) => ({ label: value, value }))}
      dataKey="value"
      dataValue="label"
      onChange={(e) => setNewFrequency(e.value)}
      placeholder="Select a frequency"
    />
  )
}

function HRSTPurposeTemplate({
  purpose, hrstOtherPurpose, newPurpose, setNewPurpose, setNewOtherPurpose, isEditing,
}) {
  if (!isEditing) {
    if (!purpose) {
      return (
        <div className="flex flex-row gap-1">
          <i className="pi pi-exclamation-triangle text-orange-500" />
          <span className="text-xs">Click edit to set purpose</span>
        </div>
      )
    }
    return (
      <div className="flex flex-column gap-2" style={{ minWidth: '160px', maxWidth: '160px' }}>
        <span>{allowedHrstPurposes[purpose]}</span>
        {
          purpose === 'OTHER' && <span>{hrstOtherPurpose}</span>
        }
      </div>
    )
  }

  return (
    <div className="flex flex-column gap-2">
      <Dropdown
        className="w-12"
        value={newPurpose}
        options={map(allowedHrstPurposes, (value, key) => ({ label: value, value: key }))}
        dataKey="value"
        dataValue="label"
        onChange={(e) => setNewPurpose(e.value)}
        placeholder="Select a purpose"
      />
      {
        newPurpose === 'OTHER' && (
          <InputText
            value={hrstOtherPurpose}
            onChange={(e) => setNewOtherPurpose(e.target.value)}
            placeholder="Enter purpose"
          />
        )
      }
    </div>
  )
}

function EditTemplate({
  doseSig,
  editingDose,
  loading,
  onEdit,
  onEditCancel,
  onEditSave,
}) {
  const { id } = doseSig

  if (editingDose?.id === id) {
    return (
      <div className="flex flex-column gap-2">
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-text"
          onClick={onEditSave}
          loading={loading}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-text"
          onClick={onEditCancel}
        />
      </div>
    )
  }
  return (
    <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-text"
      onClick={onEdit}
    />
  )
}

function HRSTDoses({ patient, handleSetHeader }) {
  if (!patient) return null

  const statusMessageRef = useRef()
  const {
    data: doseSigs,
    isLoading,
  } = useDoseSigsQuery({
    patientId: patient.id,
    statusMessage: statusMessageRef,
  })

  const {
    mutateAsync: updateDoseSig,
    isLoading: isEditLoading,
  } = useUpdateDoseSigMutation()

  const {
    mutateAsync: submitMedications,
    isLoading: submitMedicationsLoading,
  } = useSubmitMedicationsMutation(statusMessageRef, patient.id)

  const [editingDose, setEditingDose] = useState()
  const [newHrstPurpose, setNewHrstPurpose] = useState()
  const [newHrstFrequency, setNewHrstFrequency] = useState()
  const [newHrstOtherPurpose, setNewHrstOtherPurpose] = useState()

  useEffect(() => {
    handleSetHeader({ primaryAction: null })
  }, [patient])

  const medicineTemplate = ({
    dose: {
      orderText, medicine,
    },
  }) => (
    <div className="flex flex-column gap-2">
      {isEmpty(orderText)
        ? (
          <>
            <span style={{ wordBreak: 'break-all' }}>{medicine.name}</span>
            <span style={{ wordBreak: 'break-all' }}>{medicine.strength}</span>
          </>
        )
        : <span style={{ wordBreak: 'break-all' }}>{orderText}</span>}

    </div>
  )

  const schedulesTemplate = ({ prn, schedules }) => {
    if (prn) {
      return (
        <Tag style={{ background: prn ? 'var(--green-400)' : 'var(--bluegray-200)' }}>
          <span className="text-xs font-normal">PRN</span>
        </Tag>
      )
    }
    return (
      <div className="flex flex-column gap-2">
        {schedules.map((schedule) => (
          <span key={schedule} className="text-xs">
            {titleCase(schedule.text)}
            {' '}
            at
            {' '}
            {timeTemplate(schedule.time)}
          </span>
        ))}
      </div>
    )
  }

  const purposeTemplate = ({ id, hrstPurpose, hrstOtherPurpose }) => (
    <HRSTPurposeTemplate
      purpose={hrstPurpose}
      hrstOtherPurpose={hrstOtherPurpose}
      newPurpose={newHrstPurpose}
      setNewPurpose={setNewHrstPurpose}
      newOtherPurpose={newHrstOtherPurpose}
      setNewOtherPurpose={setNewHrstOtherPurpose}
      isEditing={editingDose?.id === id}
      isEditLoading={isEditLoading}
    />
  )

  const frequencyTemplate = (doseSig) => {
    const { id, hrstFrequency, hl7ScheduleCodeDescriptions } = doseSig
    const administrationAmountDisplay = formatDoseAmount({
      administrationAmount: doseSig.administrationAmount,
      units: doseSig.units,
      medicine: doseSig.dose.medicine,
    })
    return (
      <div className="flex flex-column gap-2">
        <FrequencyTemplate
          frequency={hrstFrequency}
          newFrequency={newHrstFrequency}
          setNewFrequency={setNewHrstFrequency}
          // From pgarmacy we can receive multiple repeat codes, but HRST only accepts one
          // So we use the first one
          hl7ScheduleCodeFrequency={get(hl7ScheduleCodeDescriptions, '[0]')}
          isEditing={editingDose?.id === id}
          isEditLoading={isEditLoading}
        />
        <span>{administrationAmountDisplay}</span>
      </div>
    )
  }

  const editTemplate = (doseSig) => (
    <EditTemplate
      doseSig={doseSig}
      editingDose={editingDose}
      loading={isEditLoading}
      onEdit={() => {
        setEditingDose(doseSig)
        setNewHrstPurpose(doseSig.hrstPurpose)
        setNewHrstFrequency(doseSig.hrstFrequency)
        setNewHrstOtherPurpose(doseSig.hrstOtherPurpose)
      }}
      onEditCancel={() => setEditingDose(null)}
      onEditSave={async () => {
        await updateDoseSig({
          doseSig: {
            id: doseSig.id,
            hrstPurpose: newHrstPurpose,
            hrstFrequency: newHrstFrequency,
            hrstOtherPurpose: newHrstOtherPurpose,
          },
        })
        setEditingDose(null)
      }}
    />
  )

  const detailsTemplate = (doseSig) => {
    const tag = { value: doseSig.prn ? 'PRN' : 'Schedule', rounded: false, severity: 'info' }
    const startAt = momentFromIso8601Str(doseSig.startAtWall)
    const endAt = momentFromIso8601Str(doseSig.endAtWall)
    return (
      <div className="flex flex-column gap-2">
        <Tag {...tag} className="w-4rem" />
        {doseSig.pharmacyInstructions && <span>{doseSig.pharmacyInstructions}</span>}
        { startAt?.isValid() && (
        <span>
          Start:
          {' '}
          {startAt.format(momentFormats.dateYear)}
        </span>
        ) }
        { endAt?.isValid() && (
        <span>
          End:
          {' '}
          {endAt.format(momentFormats.dateYear)}
        </span>
        ) }
      </div>
    )
  }

  const everySigHasNoPurpose = every(doseSigs, (doseSig) => !doseSig.hrstPurpose)
  const sigsWithoutPurpose = doseSigs.filter((doseSig) => !doseSig.hrstPurpose)

  return (
    <div className="col-12 flex flex-column gap-2">
      <Messages ref={statusMessageRef} />
      <div className="flex flex-row gap-2 justify-content-between align-items-center">
        <span className="text-lg font-bold">Doses</span>
        {
          patient.hrstParticipant && (
          <div className="flex flex-column align-items-center justify-content-center">
            <Button
              label="Update in HRST"
              className="p-button-sm p-button-text"
              icon="pi pi-refresh"
              onClick={submitMedications}
              loading={submitMedicationsLoading}
              disabled={everySigHasNoPurpose}
            />
            <span className="text-xs">
              Updated:
              {' '}
              {get(patient, 'hrstParticipant.medicationsUpdatedAt') ? dateTimeTemplateFromMoment(moment(get(patient, 'hrstParticipant.medicationsUpdatedAt'))) : 'Never'}
            </span>
            {
              !isLoading && everySigHasNoPurpose && (
              <span className="text-xs text-orange-500">Please set a purpose for at least one medication</span>
              )
            }
            {
              !isLoading && !everySigHasNoPurpose && sigsWithoutPurpose.length > 0 && (
                <span className="text-xs">
                  {sigsWithoutPurpose.length}
                  {' '}
                  Medication
                  {sigsWithoutPurpose.length > 1 ? 's' : ''}
                  {' '}
                  will be skipped due to missing purpose
                </span>
              )
            }
          </div>
          )
        }

      </div>
      <DataTable
        value={doseSigs}
        loading={isLoading}
        rowGroupMode="rowspan"
        groupRowsBy={['dose.id']}
      >
        <Column
          field="dose.id"
          header="Dose"
          body={medicineTemplate}
          style={{ minWidth: '150px', maxWidth: '150px' }}
        />
        <Column
          header="Details"
          body={detailsTemplate}
          style={{ minWidth: '170px', maxWidth: '170px' }}
        />
        <Column
          header="Schedules"
          body={schedulesTemplate}
          style={{ minWidth: '200px', maxWidth: '200px' }}
        />

        <Column
          header="Frequency"
          body={(rowData) => frequencyTemplate(rowData)}
        />
        <Column
          field="purpose"
          header="Purpose"
          body={(rowData) => purposeTemplate(rowData)}
        />
        <Column
          header=""
          body={(rowData) => editTemplate(rowData)}
          style={{ minWidth: '100px', maxWidth: '100px' }}

        />
      </DataTable>
    </div>
  )
}

export default HRSTDoses

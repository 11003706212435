import moment from 'moment'
import { startCase, camelCase, get } from 'lodash'
import { statusLabels } from '../components/patients/AdherenceDashboard/adherenceDashboardConfig'

const momentTz = require('moment-timezone')

function downloadFileFromResponse(response) {
  const filename = response.headers['content-disposition']
    .split('filename=')[1]
    .trim()
  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  })
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = `${filename}`
  a.click()
}

function downloadFileFromBase64Response(response) {
  const filename = response.headers['content-disposition']
    .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
    .replace(/"/g, '')
    .trim()

  const blob = new Blob([Buffer.from(response.data, 'base64')], {
    type: response.headers['content-type'],
  })
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.click()
}

const dateTemplate = (datestring) => moment(datestring).format('MMM Do, YYYY')

const getValidatedDateFormat = (datestring) => {
  const formattedDate = dateTemplate(datestring)
  return moment(formattedDate, 'MMM Do, YYYY').isValid() ? formattedDate : ''
}

const timeTemplate = (datetime) => moment(datetime).format('h:mm A')

const hourMinuteTemplate = (hour, minute) => moment().set({
  hour, minute, second: 0, millisecond: 0,
}).format('h:mm A')

const convertTimeToDate = (hour, minute) => {
  const date = new Date()
  date.setHours(hour, minute, 0, 0)
  return date
}

const dateTimeTemplateFromMoment = (dateMoment) => dateMoment.format('MMM Do, YYYY h:mm A')

const formatMinutesDuration = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  let text = ''
  if (hours) {
    text = hours === 1 ? `${hours} Hour ` : `${hours} Hours`
    if (remainingMinutes) {
      text += ' and '
    }
  }
  if (remainingMinutes) {
    text += remainingMinutes === 1 ? `${remainingMinutes} Minute ` : `${remainingMinutes} Minutes`
  }
  return text
}

function convertToTimeZoneDate(dateTimeString, timezone) {
  return new Date(moment.tz(dateTimeString, timezone).format('YYYY-MM-DDTHH:mm:ss'))
}

function formatDateTimeLocale(datestring, timezone) {
  if (!datestring) return ''

  const momentDate = momentTz.tz(datestring, timezone)
  return momentDate.format('MMM DD, YYYY h:mm A')
}

const formattedTimeFromDate = (dateMoment, timezone) => {
  const momentDate = momentTz.tz(dateMoment, timezone)
  return momentDate.format('h:mm A')
}

const isBeforeNow = (date) => {
  const parsedDate = moment(date)
  const now = moment()

  return parsedDate.isBefore(now)
}

const datetimeTemplate = (datetime) => {
  const date = new Date(datetime).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  const time = new Date(datetime).toLocaleTimeString('en-us', {
    hour: '2-digit',
    minute: '2-digit',
  })

  return `${time} on ${date}`
}

const getOneWeekAgoDateFormatted = () => moment().subtract(7, 'days').format('MMM Do, YYYY')

const getOneMonthFromNowFormattedDate = () => moment().add(1, 'month').format('MMM Do, YYYY')

function generateFormattedDateTimeString(selectedDate, selectedTime) {
  return `${selectedDate.format('YYYY-MM-DD')}T${selectedTime}${selectedDate.format('Z')}`
}

const titleCase = (name) => startCase(camelCase(name))

const capitalization = (name) => name.charAt(0).toUpperCase() + name.slice(1)

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = []

  const currDate = moment(startDate).startOf('day')
  const lastDate = moment(endDate).endOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }

  return dates
}

const enumerateDaysBetweenMoments = (
  startDate,
  endDate,
) => {
  const dates = []

  const currDate = startDate.clone().startOf('day')
  const lastDate = endDate.clone().endOf('day')

  while (currDate.diff(lastDate) < 0) {
    dates.push(currDate.clone())
    currDate.add(1, 'days')
  }

  return dates
}

const generatePastTwelveMonths = () => {
  const today = new Date()
  return Array.from({ length: 12 }).map((_, index) => {
    const date = new Date()
    date.setDate(1)
    date.setHours(0, 0, 0)
    date.setMonth(today.getMonth() - index)
    return {
      value: date,
      label: date.toLocaleDateString('en-us', { year: 'numeric', month: 'long' }),
    }
  })
}

/// Returns an array of months from January to December
const generateAllMonths = () => [...Array(12).keys()].map((i) => ({
  label: moment().month(i).format('MMMM'),
  value: i + 1,
}))

/// Returns an array of years from the current year to the number of years ago
const generateYears = (yearsAgo) => {
  const now = moment()
  const years = [now.year()]
  for (let i = 1; i < yearsAgo; i += 1) {
    years.push(now.year() - i)
  }
  return years
}

const generateTimeIncrements = ({ minuteIncrement, durationHours }) => {
  const times = []
  const startTime = moment()
  startTime.set({
    hour: '0',
    minute: '0',
    second: '0',
    millisecond: '0',
  })
  const endTime = startTime.clone().add(durationHours, 'hours')

  while (startTime < endTime) {
    times.push({ label: startTime.format('h:mm A'), value: startTime.valueOf() })
    startTime.add(minuteIncrement, 'minutes')
  }

  return times
}

const formatedCCMTime = (hours, minutes) => {
  let text = ''
  if (!hours && !minutes) {
    return 'No Services Provided'
  }
  if (hours) {
    text = hours === 1 ? `${hours} Hour ` : `${hours} Hours`
    if (minutes) {
      text += ' and '
    }
  }
  if (minutes) {
    text += minutes === 1 ? `${minutes} Minute ` : `${minutes} Minutes`
  }
  text += ' of Services Provided'
  return text
}

/**
 * Safely calculate today's time from a given minutes offset.
 * This function exists to avoid issues with moment.js and timezones.
 * Previously, we were using moment().startOf('day').add(minutes, 'minutes'), but this would add
 * or subtract an extra hour when there is a daylight savings time change between the start of the
 * day and the minutes offset.
 */
const getTimeFromMinutes = (minutes) => moment()
  .set({
    hour: Math.floor(minutes / 60), minute: minutes % 60, second: 0, millisecond: 0,
  })

/// Returns the time of day string from a schedule, e.g. 9:00 AM
/// schedule should have a time property in minutes
const formattedTimeFromMinutes = (minutes) => getTimeFromMinutes(minutes)
  .format('h:mm A')

const initials = (name) => {
  const rgx = /(\p{L}{1})\p{L}+/gu
  const nameInitials = [...name.matchAll(rgx)] || []

  return ((nameInitials.shift()?.[1] || '') + (nameInitials.pop()?.[1] || '')).toUpperCase()
}

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

const permissionValueToLabel = (value, organization) => {
  if (value === 'client') {
    return organization.patientLabelSingular
  } if (value === 'staff') {
    return organization.caregiverLabelSingular
  } if (value === 'both') {
    return `${organization.patientLabelSingular} and ${organization.caregiverLabelSingular}`
  } if (value === 'no_restriction') {
    return 'None'
  }
  return null
}

const roleToOrganizationLabel = (value, organization, { singular = false } = {}) => {
  if (value === 'patient') {
    if (singular) return organization.patientLabelSingular
    return organization.patientLabelPlural
  } if (value === 'caregiver') {
    if (singular) return organization.caregiverLabelSingular
    return organization.caregiverLabelPlural
  } if (value === 'site_admin') {
    if (singular) return organization.adminLabelSingular
    return organization.adminLabelPlural
  }
  return value
}
const groupedUsersByRole = (users, organization) => {
  const roles = ['caregiver', 'patient', 'site_admin', 'Deactivated']
  const groupedByRole = roles.map((role) => ({
    role: roleToOrganizationLabel(role, organization),
    users: [],
  }))
  users.forEach((user) => {
    const { role, currentUserActivation } = user
    if (currentUserActivation.deactivatedAt) {
      groupedByRole.find((group) => group.role === 'Deactivated').users.push(user)
      return
    }
    const roleLabel = roleToOrganizationLabel(role, organization)
    const roleIndex = groupedByRole.findIndex((group) => group.role === roleLabel)
    groupedByRole[roleIndex].users.push(user)
  })
  return groupedByRole.filter((group) => group.users.length > 0)
}

const getCssVariable = (variableName) => getComputedStyle(document.documentElement)
  .getPropertyValue(variableName)

const getConsumptionsStatusColor = (code) => {
  const statusArray = [
    { value: 'CONFIRMED', color: getCssVariable('--circle-given') },
    { value: 'OVERRIDE_CONFIRMED', color: getCssVariable('--circle-given') },
    { value: 'OVERRIDE_MISSED', color: getCssVariable('--circle-missed') },
    { value: 'OVERRIDE_PENDING', color: getCssVariable('--circle-pending') },
    { value: 'OVERRIDE_LOA', color: getCssVariable('--circle-loa') },
    { value: 'OVERRIDE_REFUSED', color: getCssVariable('--circle-refused') },
    { value: 'CREATE_CONFIRMED', color: getCssVariable('--circle-given') },
    { value: 'CREATE_MISSED', color: getCssVariable('--circle-missed') },
    { value: 'CREATE_REFUSED', color: getCssVariable('--circle-refused') },
    { value: 'missed', color: getCssVariable('--circle-missed') },
    { value: 'confirmed', color: getCssVariable('--circle-given') },
    { value: 'LOA', color: getCssVariable('--circle-loa') },
    { value: 'refused', color: getCssVariable('--circle-refused') },
    { value: 'on_hold', color: getCssVariable('--circle-on-hold') },
    { value: 'completed', color: getCssVariable('--circle-given') },
    { value: 'skipped', color: getCssVariable('--circle-loa') },
  ]

  const statusObj = statusArray.find((status) => status.value === code)
  return get(statusObj, 'color', 'transparent')
}

const getConsumptionStatusLabel = (code) => {
  const statusObj = statusLabels[code]
  return statusObj || 'Unknown'
}

const isDateInPast = (date) => {
  if (!date) return false
  return date < new Date()
}

export {
  capitalization,
  convertTimeToDate,
  dateTemplate,
  datetimeTemplate,
  dateTimeTemplateFromMoment,
  downloadFileFromBase64Response,
  downloadFileFromResponse,
  enumerateDaysBetweenDates,
  enumerateDaysBetweenMoments,
  formatDateTimeLocale,
  formatedCCMTime,
  formatMinutesDuration,
  formattedTimeFromMinutes,
  generateAllMonths,
  generatePastTwelveMonths,
  generateTimeIncrements,
  generateYears,
  getTimeFromMinutes,
  getValidatedDateFormat,
  hourMinuteTemplate,
  initials,
  isElementInViewport,
  permissionValueToLabel,
  roleToOrganizationLabel,
  timeTemplate,
  titleCase,
  isBeforeNow,
  formattedTimeFromDate,
  getOneWeekAgoDateFormatted,
  getOneMonthFromNowFormattedDate,
  getConsumptionStatusLabel,
  isDateInPast,
  getConsumptionsStatusColor,
  generateFormattedDateTimeString,
  groupedUsersByRole,
  convertToTimeZoneDate,
  getCssVariable,
}

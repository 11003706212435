import React from 'react'
import { Button } from 'primereact/button'
import moment from 'moment'
import { momentFormats } from '@services/utils/moment'
import DoseConsumptionsHistory from './DoseConsumptionsHistory'

const getStatusInfo = (status) => {
  if (!status) return {}
  // const isConfirmed = status === 'confirmed'
  // return {
  //   label: isConfirmed ? 'Confirmed' : 'Refused',
  //   iconClass: isConfirmed ? 'p-bg-circle-given' : 'p-bg-circle-refusal',
  // }
  switch (status) {
    case 'confirmed':
      return {
        label: 'Confirmed',
        iconClass: 'p-bg-circle-given',
      }
    case 'refused':
      return {
        label: 'Refused',
        iconClass: 'p-bg-circle-refusal',
      }
    case 'missed':
      return {
        label: 'Missed',
        iconClass: 'p-bg-circle-missed',
      }
    default:
      return {
        label: 'Unspecified',
        iconClass: 'p-bg-circle-unspecified',
      }
  }
}

function StatusHeader({ consumption, handleEdit }) {
  const { status } = consumption
  const { label, iconClass } = getStatusInfo(status)

  return (
    <div className="flex flex-row align-items-center">
      <div className="flex flex-row gap-2">
        <i className={`pi pi-circle-fill ${iconClass}`} />
        <div className="text-base font-medium line-height-2">{label}</div>
      </div>
      { handleEdit && (
        <Button label="Edit" className="p-button-sm p-button-text text-xs p-0 ml-auto" onClick={(e) => handleEdit(e, consumption)} />
      )}
    </div>
  )
}

function StatusDetails({
  expectedAt, confirmedBy, confirmedAt, reason, status, isPrn, prnReason, timezone,
}) {
  const missedOrRefused = status === 'missed' || status === 'refused'
  const hasReasons = isPrn || missedOrRefused
  return (
    <div className="flex flex-column w-full text-left">
      <div className="flex flex-column mt-2 row-gap-2">
        { expectedAt && (
        <div className="flex flex-row gap-1">
          <span className="text-xs">Expected:</span>
          <span className="text-xs font-medium">{moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
        </div>
        )}
        {confirmedAt && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">Confirmed:</span>
            <span className="text-xs font-medium">{moment(confirmedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{confirmedBy.fullName}</span>
          </div>
        </div>
        )}
      </div>
      {!hasReasons ? <div className="mt-2" /> : (
        <div className="flex flex-column row-gap-2 mt-3 mb-2">
          {isPrn && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">PRN Reason: </span>
              <span className="text-xs font-medium">{prnReason || 'Unspecified'}</span>
            </div>
          )}
          {missedOrRefused && (
            <div className="flex flex-row gap-1">
              <span className="text-xs">Reason: </span>
              <span className="text-xs font-medium">{reason || 'Unspecified'}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
function StatusOverlayContent({ timezone, consumption, handleEdit }) {
  return (
    <div className="flex flex-column p-1">
      <StatusHeader consumption={consumption} handleEdit={handleEdit} />
      <StatusDetails {...consumption} timezone={timezone} />
      <DoseConsumptionsHistory
        timezone={timezone}
        historyData={consumption.administrationEvents || []}
      />
    </div>
  )
}

export default React.memo(StatusOverlayContent)

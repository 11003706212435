import React, { useRef, useEffect, useState } from 'react'
import {
  isEmpty,
} from 'lodash'
import { useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import Exports from '@components/patients/Exports'
import { hasPrnSig, hasSchedules } from '@components/clientDoses/doseUtils'
import { useCurrentUser } from '@components/App'
import AttributeCard from '@components/display/AttributeCard'
import MedicalConditions from '@components/display/MedicalConditions/MedicalConditions'
import ClientDoseSchedule from './Schedule'
import DoseConditions from './DoseConditions'
import {
  useEndDoseMutation,
  usePatientDose,
  useGenerateConsumptions,
  usePauseDoseMutation,
  useUnpauseDoseMutation,
} from '../clientDosesHooks'
import './client-dose.scss'
import PrnSettings from './PrnSettings'
import DoseHeader from './DoseHeader'
import DosingTable from './DosingTable'
import SigTable from './SigTable'
import Source from './Source'
import DoseMARExportDialog from './DoseMARExportDialog'
import Orders from './Orders'
import Prescriptions from './Prescriptions'
import DoseFills from './DoseFills'

function ClientDose({ handleSetHeader, patient }) {
  const scheduleStatusMessage = useRef(null)

  const { patientId: clientId, doseId } = useParams()
  const currentUser = useCurrentUser()

  const {
    data: dose,
    isLoading,
  } = usePatientDose({ clientId, doseId })

  const {
    mutateAsync: generateConsumptions,
  } = useGenerateConsumptions(doseId, scheduleStatusMessage)

  const {
    mutateAsync: endDose,
  } = useEndDoseMutation(clientId, scheduleStatusMessage)

  const {
    mutateAsync: pauseDose,
  } = usePauseDoseMutation(clientId, scheduleStatusMessage)

  const {
    mutateAsync: unpauseDose,
  } = useUnpauseDoseMutation(clientId, scheduleStatusMessage)

  const [isMarDialogVisible, setIsMarDialogVisible] = useState(false)

  useEffect(() => handleSetHeader({ primaryAction: null }), [patient])

  if (isLoading || isEmpty(dose)) {
    return 'Loading...'
  }

  const primaryActionSetup = (action) => {
    handleSetHeader({ primaryAction: action })
  }

  return (
    <>
      <Toast ref={scheduleStatusMessage} />
      <Exports
        patientId={clientId}
        exportsMenuSetupCallBack={primaryActionSetup}
      />
      <DoseMARExportDialog
        doseId={doseId}
        isVisible={isMarDialogVisible}
        setIsVisible={setIsMarDialogVisible}
      />
      <DoseHeader
        dose={dose}
        endDose={endDose}
        pauseDose={pauseDose}
        unpauseDose={unpauseDose}
        generateConsumptions={generateConsumptions}
        setMarDialogVisible={setIsMarDialogVisible}
      />
      <DosingTable dose={dose} timezone={patient?.timezone} />
      {
        dose.sigs?.map((sig, index) => (
          <SigTable
            key={sig.sigNumber ?? index}
            dose={dose}
            sig={sig}
            timezone={patient?.timezone}
          />
        ))
      }
      <DoseConditions
        doseSchedules={dose.schedules}
        dose={dose}
      />
      {
        hasSchedules(dose) && (
          <ClientDoseSchedule
            clientId={clientId}
            dose={dose}
            scheduleStatusMessage={scheduleStatusMessage}
          />
        )
      }
      {
        hasPrnSig(dose) && (
          <PrnSettings
            dose={dose}
            patientId={clientId}
          />
        )
      }
      <div className="col-12" data-testid="medical-conditions">
        <AttributeCard title={{ label: 'Medical Conditions' }}>
          <MedicalConditions doseId={doseId} patientId={clientId} />
        </AttributeCard>
      </div>
      <Source dose={dose} />
      {
        // TODO: Remove this superadmin check after hoisting layout
        currentUser.role === 'superadmin' && (
          <>
            <Prescriptions doseId={doseId} />
            <Orders doseId={doseId} />
            <DoseFills doseId={doseId} />
          </>
        )
      }
    </>
  )
}

export default ClientDose

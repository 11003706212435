import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

function DosePausingDialog({
  visible, onHide, onConfirm,
}) {
  const [note, setNote] = useState('')

  const footer = (
    <div className="flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text p-button-danger p-button-sm"
        onClick={onHide}
      />
      <Button
        label="Hold"
        icon="pi pi-check"
        className="p-button-text p-button-success p-button-sm"
        onClick={() => onConfirm(note)}
      />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Hold Medication"
      footer={footer}
      modal
      className="p-fluid"
    >
      <div className="p-field p-2">
        <label htmlFor="pause-dose-note">Leave a note or reason</label>
        <InputTextarea
          inputId="pause-dose-note"
          id="pause-dose-note"
          className="mt-2"
          rows={3}
          cols={30}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          autoFocus
        />
      </div>
    </Dialog>
  )
}

export default DosePausingDialog

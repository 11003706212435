import React, { useRef, useState } from 'react'
import { filter, split } from 'lodash'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import AttributeCard from '@components/display/AttributeCard'
import { OnboardTaskTable } from '@components/tasks/OnboardTaskTable'
import { useAcknowledgeTreatment } from '@hooks/treatments'

const getModeText = (mode) => {
  switch (mode) {
    case 'task':
      return 'A task will be created using the treatment details and the following information.'
    case 'remind':
      return 'Select this option if this treatment has no task to complete and is more of a user preference (e.g. ENCOURAGE SECONDS OF FRUITS/VEGGIES). This treatment will appear as a reminder during each med pass.'
    default:
      return ''
  }
}

function ActionSection({ treatment }) {
  const statusMessage = useRef(null)
  const [selectedMode, setSelectedMode] = useState('task')
  const [task, setTask] = useState({})

  const {
    mutateAsync: acknowledgeTreatment,
  } = useAcknowledgeTreatment({ statusMessage })

  const helpText = 'Choose how to handle onboarding this treatment.'

  const handleActionChange = (e) => {
    setSelectedMode(e.value)
  }

  const handleOnboardTreatment = async () => {
    const params = {
      id: treatment.id,
      mode: selectedMode,
    }

    if (selectedMode === 'task') {
      params.task = {
        taskType: task.taskType,
        taskAction: task.taskAction,
        title: task.title,
        description: task.description,
        showOnTar: task.showOnTar,
        schedules: task.schedules.map((schedule) => ({
          flexibilityAttributes: schedule.flexibility,
          time_hh_mm: schedule.time_hh_mm,
          // The rrule.js library converts rrules to string
          // in format "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=WE,FR"
          // The below line extracts the rule itself, omitting the "RRULE:"
          // so that it is compatible with API validations
          rrule: filter(split(schedule.rrule, ':'), (rule) => rule !== 'RRULE').join(';'),
        })),
      }
    }

    await acknowledgeTreatment(params)
  }

  const onboardButtonTemplate = () => (
    <Button
      label="Onboard Treatment"
      className="p-button-sm"
      onClick={handleOnboardTreatment}
    />
  )

  return (
    <div className="col-12">
      <Toast ref={statusMessage} />
      <AttributeCard title={{ helpText, label: 'Action' }} ActionComponent={onboardButtonTemplate}>
        <div className="flex flex-row justify-content-center">
          <SelectButton
            allowEmpty={false}
            onChange={handleActionChange}
            optionLabel="label"
            optionValue="value"
            options={[
              { label: 'Add as Task', value: 'task' },
              { label: 'Reminder in Med Pass', value: 'remind' },
            ]}
            placeholder="Select an option"
            value={selectedMode}
          />
        </div>
        <div className="flex flex-row justify-content-center">
          <span className="opacity-70 text-center pt-2 pb-2 w-8">{getModeText(selectedMode)}</span>
        </div>
        {
          selectedMode === 'task' && (
            <OnboardTaskTable
              treatment={treatment}
              task={task}
              setTask={setTask}
            />
          )
        }
      </AttributeCard>
    </div>
  )
}

export default ActionSection

import React, { useState, useRef, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { useCurrentOrganization } from '@components/App'
import moment from 'moment'
import { Toast } from 'primereact/toast'
import {
  generateFormattedDateTimeString, roleToOrganizationLabel, groupedUsersByRole,
} from '@services/utils'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { useCanAdministerConsumption } from '@services/hooks'
import { momentFormats, momentToDatePreservingTime } from '@services/utils/moment'
import {
  createStatusArray, overrideStatusArray, refusedReasonArray, shouldShowAdministeredBy,
} from '../adherenceDashboardUtils'
import { useCreatePatientConsumptionMutation, useUpdatePatientConsumptionMutation } from '../../../../features/Patients/patientHooks'

function ConsumptionStatusForm({
  visible,
  onHide,
  consumptionToBeUpdated,
  cellTime,
  marTimeRow,
  timezone,
  mode,
  patientId,
}) {
  const [formData, setFormData] = useState({
    administeredBy: '',
    reason: '',
    refusedReason: '',
    status: '',
    comments: '',
    time: '',
  })
  const organization = useCurrentOrganization()
  const statusMessage = useRef(null)
  const [deactivatedUserAgreed, setDeactivatedUserAgreed] = useState(false)
  const { data: users, isLoading: usersLoading } = useCanAdministerConsumption(
    {
      statusMessage,
      patientId,
      willConfirmAt: formData.time ? moment(formData.time).format(momentFormats.iso8601_utc) : null,
    },
  )

  const {
    mutateAsync: createConsumption,
    isLoading: createIsLoading,
  } = useCreatePatientConsumptionMutation(statusMessage)

  const {
    mutateAsync: updateConsumption,
    isLoading: updateIsLoading,
  } = useUpdatePatientConsumptionMutation(consumptionToBeUpdated?.id, statusMessage)

  useEffect(() => {
    if (visible) {
      setFormData({
        ...formData,
        time: consumptionToBeUpdated
          ? momentToDatePreservingTime(moment(consumptionToBeUpdated.expectedAt), timezone)
          : momentToDatePreservingTime(cellTime, timezone),
      })
    }
  }, [visible])

  if (!cellTime || !marTimeRow) {
    return null
  }

  const selectedDateFormatted = cellTime?.format('MM/DD/YYYY')
  const isAddMode = mode === 'add'
  const statusOptions = isAddMode ? createStatusArray : overrideStatusArray

  const dialogHeaderTemplate = () => (
    <div className="flex flex-column align-items-baseline w-full">
      <div className="font-semibold text-lg">{isAddMode ? 'Administer Dose' : 'Edit Status'}</div>
    </div>
  )

  const administeredByOptionTemplate = (option) => (
    <div>
      <span>{option.fullName}</span>
      <span className="text-xs ml-1 text-500">
        (
        {roleToOrganizationLabel(option.role, organization, { singular: true }) || 'No Role'}
        )
      </span>
      {
        option.currentUserActivation.deactivatedAt && (
          <span className="text-xs ml-1 text-red-500">Deactivated</span>
        )
      }
    </div>
  )

  const administeredByTemplate = (option, props) => {
    if (option) {
      return administeredByOptionTemplate(option)
    }
    return <span>{props.placeholder}</span>
  }

  const onSubmit = async () => {
    const {
      administeredBy, reason, refusedReason, status, comments, time,
    } = formData
    const selectedTime = moment(time).format('HH:mm:ss')
    const consumptionConfirmedDate = generateFormattedDateTimeString(
      cellTime,
      selectedTime,
    )

    let body = {
      code: status,
      note: comments,
      subjectId: administeredBy.id,
      confirmedAt: consumptionConfirmedDate,
      timestamp: consumptionConfirmedDate,
      expectedAt: consumptionToBeUpdated?.expectedAt || consumptionConfirmedDate,
      reason,
      refusedCode: refusedReason,
      doseId: marTimeRow.doseSig?.dose.id,
      doseSigId: marTimeRow.doseSig?.id,
      consumptionTime: consumptionToBeUpdated?.expectedAt || consumptionConfirmedDate,
      patientId,
    }

    if (isAddMode) {
      body = { consumption: body }
    }

    const action = isAddMode ? createConsumption : updateConsumption
    await action(body, {
      onSuccess: () => {
        onHide()
        setFormData({
          administeredBy: '',
          reason: '',
          refusedReason: '',
          status: '',
          comments: '',
          time: '',
        })
      },
    })
  }

  const handleStatusChange = (e) => {
    if (e.value === 'CREATE_MISSED' || e.value === 'OVERRIDE_MISSED') {
      setFormData({
        ...formData, reason: '', administeredBy: '', status: e.value,
      })
    } else {
      setFormData({ ...formData, status: e.value })
    }
  }

  const needsDeactivateConfirmation = formData.administeredBy
  && formData.administeredBy.currentUserActivation.deactivatedAt

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        onHide()
        setFormData({
          administeredBy: '',
          reason: '',
          refusedReason: '',
          status: '',
          comments: '',
          time: '',
        })
      }}
      header={dialogHeaderTemplate}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column gap-3 w-22rem mt-1">
        <div className="flex flex-column gap-3 w-22rem mt-1">
          <div className="flex flex-column gap-1">
            <label className="block" htmlFor="status">Status</label>
            <Dropdown
              id="consumption-status"
              value={formData.status}
              options={statusOptions}
              onChange={(e) => handleStatusChange(e)}
              placeholder="Please select a status"
              className="w-full text-sm"
            />
          </div>
          {
            shouldShowAdministeredBy(formData.status)
            && (
            <div className="flex flex-column gap-1">
              <label className="block" htmlFor="name">Administered by</label>
              <Dropdown
                id="consumption-administered-by"
                value={formData.administeredBy}
                options={groupedUsersByRole(users, organization)}
                optionGroupLabel="role"
                optionGroupChildren="users"
                optionLabel="fullName"
                loading={usersLoading}
                onChange={(e) => setFormData({ ...formData, administeredBy: e.value })}
                valueTemplate={administeredByTemplate}
                itemTemplate={administeredByOptionTemplate}
                placeholder="Select a name"
                className="w-full text-sm"
                filter
                showFilterClear
              />
              {
                needsDeactivateConfirmation && (
                  <div key="deactivatedUserAgreed" className="flex align-items-center">
                    <Checkbox
                      inputId="deactivatedUserAgreed"
                      checked={deactivatedUserAgreed}
                      onChange={(e) => setDeactivatedUserAgreed(e.checked)}
                      className="text-xs"
                    />
                    <label htmlFor="deactivatedUserAgreed" className="ml-2">I understand that this user is deactivated</label>
                  </div>
                )
              }
            </div>
            )
          }
          <div className="flex flex-row gap-2">
            <div className="flex-1">
              <label className="block mb-1">Date</label>
              <InputText className="w-full" value={selectedDateFormatted} disabled />
            </div>
            <div className="flex-1">
              <label className="block mb-1" htmlFor="time">Time</label>
              <Calendar
                value={formData.time}
                onChange={(e) => setFormData({ ...formData, time: e.value })}
                timeOnly
                hourFormat="12"
                showIcon
                icon={<i className="pi pi-clock text-black-alpha-70 text-white" />}
                className="w-full custom-calendar border-black-alpha-70"
              />
            </div>
          </div>
          {(formData.status === 'CREATE_REFUSED' || formData.status === 'OVERRIDE_REFUSED') && (
          <div className="flex flex-column gap-1">
            <label className="block" htmlFor="name">Reason</label>
            <Dropdown
              value={formData.refusedReason}
              options={refusedReasonArray(organization.patientLabelSingular)}
              onChange={(e) => setFormData({ ...formData, refusedReason: e.value })}
              placeholder="Please select a reason"
              className="w-full"
            />
          </div>
          )}
          {(formData.status === 'CREATE_MISSED' || formData.status === 'OVERRIDE_MISSED') && (
          <div className="flex flex-column gap-1">
            <label className="block" htmlFor="name">Reason</label>
            <InputText
              value={formData.reason}
              onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
              placeholder="Please enter a reason"
            />
          </div>
          )}
          <div className="flex flex-column gap-1">
            <label className="block" htmlFor="name">Notes</label>
            <InputTextarea
              id="consumption-comments"
              value={formData.comments}
              onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
              rows={3}
              cols={30}
            />
          </div>
          <Button
            label="Save"
            className="p-button-sm w-full mt-2"
            disabled={
              !formData.status
              || (shouldShowAdministeredBy() && !formData.administeredBy)
              || formData.comments.length === 0
              || (formData.status === 'CREATE_REFUSED' && !formData.refusedReason)
              || (formData.status === 'OVERRIDE_REFUSED' && !formData.refusedReason)
              || (formData.status === 'CREATE_CONFIRMED' && !formData.administeredBy)
              || (formData.status === 'OVERRIDE_CONFIRMED' && !formData.administeredBy)
              || (needsDeactivateConfirmation && !deactivatedUserAgreed)
            }
            onClick={onSubmit}
          >
            {(createIsLoading || updateIsLoading) && <i className="pi pi-spin pi-spinner text-lg" />}
          </Button>
        </div>

      </div>
    </Dialog>
  )
}

export default ConsumptionStatusForm

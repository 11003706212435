import React, { useState, useRef, useEffect } from 'react'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { useCurrentUser } from '@components/App'
import ExportPatientMARDialog from './PatientMARExportDialog'
import ExportPatientPRNMARDialog from './PatientPRNMARExportDialog'
import ExportPatientMedicationErrorsReportDialog from './PatientMedicationErrorsReportDialog'
import ExportPatientClinicalNotesReport from './PatientClinicalNotesExportDialog'
import ExportPatientPhysicianOrdersDialog from './PatientPhysicianOrdersDialog'
import ExportBowelMovementsReportDialog from './ExportBowelMovementsReportDialog'
import PatientNarcoticCountsReportDialog from './PatientNarcoticCountsReportDialog'
import VitalSignsReportDialog from './VitalSignsReportDialog'
import MedicationReleaseReportDialog from './MedicationReleaseReportDialog'

function Exports({
  patientId,
  exportsMenuSetupCallBack,
}) {
  const [visibleDialog, setVisibleDialog] = useState()
  const currentUser = useCurrentUser()
  const isCurrentUserAdmin = ['superadmin', 'site_admin'].includes(currentUser.role)
  const menu = useRef(null)

  const actions = [
    { label: 'MAR', command: () => setVisibleDialog('mar') },
    { label: 'PRN MAR', command: () => setVisibleDialog('prnMar') },
    { label: 'Medication Errors Report', command: () => setVisibleDialog('medicationErrorsReport') },
    { label: 'Clinical Notes Report', command: () => setVisibleDialog('clicnicalNotesReport') },
    { label: 'Physician Orders', command: () => setVisibleDialog('physicianOrders') },
    { label: 'Bowel Movements Report', command: () => setVisibleDialog('bowelMovementsReport') },
    { label: 'Narcotic Counts', command: () => setVisibleDialog('narcoticCounts') },
    { label: 'Vital Signs', command: () => setVisibleDialog('vitalSigns') },
    { label: 'Medication Release', command: () => setVisibleDialog('medicationRelease') },
  ]

  const primaryAction = isCurrentUserAdmin && (
    <div className="flex flex-row gap-2">
      <Menu model={actions} popup ref={menu} id="popup_menu" />
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-file-export"
        onClick={(event) => menu.current.toggle(event)}
        label="Exports"
      />
    </div>
  )

  useEffect(() => {
    exportsMenuSetupCallBack(primaryAction)
  }, [])

  return (
    <>
      <ExportPatientMARDialog
        patientId={patientId}
        isVisible={visibleDialog === 'mar'}
        setIsVisible={setVisibleDialog}
      />
      <ExportPatientPRNMARDialog
        patientId={patientId}
        isVisible={visibleDialog === 'prnMar'}
        setIsVisible={setVisibleDialog}
      />
      <ExportPatientMedicationErrorsReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'medicationErrorsReport'}
        setIsVisible={setVisibleDialog}
      />
      <ExportPatientClinicalNotesReport
        patientId={patientId}
        isVisible={visibleDialog === 'clicnicalNotesReport'}
        setIsVisible={setVisibleDialog}
      />
      <ExportPatientPhysicianOrdersDialog
        patientId={patientId}
        isVisible={visibleDialog === 'physicianOrders'}
        setIsVisible={setVisibleDialog}
      />
      <ExportBowelMovementsReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'bowelMovementsReport'}
        setIsVisible={setVisibleDialog}
      />
      <PatientNarcoticCountsReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'narcoticCounts'}
        setIsVisible={setVisibleDialog}
      />
      <VitalSignsReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'vitalSigns'}
        setIsVisible={setVisibleDialog}
      />
      <MedicationReleaseReportDialog
        patientId={patientId}
        isVisible={visibleDialog === 'medicationRelease'}
        setIsVisible={setVisibleDialog}
      />
    </>
  )
}

export default Exports

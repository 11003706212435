import React, {
  memo, useEffect, useRef, useState,
} from 'react'
import { useTARDashboardQuery } from '@hooks/patients/tarHooks'
import DateTable from '@components/display/DateTable/DateTable'
import { OverlayPanel } from 'primereact/overlaypanel'
import { get, groupBy, isEmpty } from 'lodash'
import { formatOccurrences, sortTarTasks, tarTasksColumns } from './TARUtils'
import StatusOverlay from './StatusOverlay'
import SingleTaskStatusTag from './StatusTag/SingleTaskStatusTag'
import MultipleTasksStatusTag from './StatusTag/MultipleTasksStatusTag'

const TarTasks = memo(({
  dateRange, timezone, patientId, type,
}) => {
  const statusOverlay = useRef()
  const [selectedOccurrences, setSelectedOccurrences] = useState()
  const [formatedTarDashboard, setFormatedTarDashboard] = useState()
  const statusMessagesRef = useRef()
  const {
    data: tarDashboard,
    isLoading,
  } = useTARDashboardQuery(patientId, dateRange, type, statusMessagesRef)

  useEffect(() => {
    setFormatedTarDashboard(sortTarTasks(formatOccurrences(tarDashboard?.tarTasks, timezone)))
  }, [tarDashboard, timezone])

  const onSelectedOccurrence = (e, occurrences) => {
    setSelectedOccurrences(occurrences)
    if (statusOverlay.current) {
      statusOverlay.current.hide()
      requestAnimationFrame(() => {
        statusOverlay.current.show(e)
      })
    } else {
      statusOverlay.current.show(e)
    }
  }

  const treatmentTemplate = (rowData) => (
    <div className="flex flex-column gap-1 align-items-start text-left">
      Instructions:
      {' '}
      {rowData.treatment.pharmacyInstructions}
    </div>
  )

  const taskTemplate = (rowData) => (
    <div className="flex flex-column gap-1 align-items-start text-left">
      <span>{rowData.task.description}</span>
    </div>
  )

  const scheduledTag = (occurrence) => (
    <SingleTaskStatusTag
      status={occurrence.status}
      initials={occurrence.actionedBy?.initials}
      onClick={(e) => onSelectedOccurrence(e, [occurrence])}
    />
  )

  const prnTag = (occurrences) => {
    const grouped = groupBy(occurrences, 'status')
    return (
      <div className="flex flex-column gap-1 align-items-center">
        {Object.keys(grouped).map((status) => (
          <MultipleTasksStatusTag
            key={status}
            count={grouped[status].length}
            type={status}
            onClick={(e) => onSelectedOccurrence(e, grouped[status])}
          />
        ))}
      </div>
    )
  }

  const occurrenceTemplate = (rowData, column) => {
    const occurrences = get(rowData, column.field)
    if (isEmpty(occurrences)) return null
    return (
      <div className="flex flex-row align-items-center justify-content-center">
        {type === 'routine' ? scheduledTag(occurrences[0]) : prnTag(occurrences)}
      </div>
    )
  }

  return (
    <div>
      <OverlayPanel ref={statusOverlay} className="dose-overlay">
        <StatusOverlay
          occurrences={selectedOccurrences}
          timezone={timezone}
        />
      </OverlayPanel>
      <DateTable
        columns={tarTasksColumns({
          dateRange,
          treatmentTemplate,
          taskTemplate,
          occurrenceTemplate,
          showTime: type === 'routine',
        })}
        data={formatedTarDashboard}
        isLoading={isLoading}
        tableClassName="dose-consumptions"
        emptyMessage="No tasks available"
        className="schedule-mar-view"
        rowGroupMode="rowspan"
        groupRowsBy={['treatment.id', 'task.id']}
        sortMode="single"
      />
    </div>
  )
})

export default TarTasks

import React, {
  useState, useRef, useCallback,
} from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import { useMarDashboardQuery } from '@hooks/patients/marHooks'
import DateTable from '@components/display/DateTable/DateTable'
import moment from 'moment'
import PRNStatusOverlay from './PRNStatusOverlay'
import { buildDateAndTimeWithTimeZone } from './prnConsumptionUtils'
import MarSigDetails from '../MarSigDetails'
import AdherenceTableHeader from '../AdherenceTableHeader'
import MARApprovalsTable from '../MARApproval/MARApprovalTable'
import ApprovalOverlay from '../MARApproval/ApprovalOverlay'
import { marColumns } from '../../adherenceDashboardUtils'
import DoseDetails from '../DoseDetails'
import StatusIndicator from './StatusIndicator'
import PRNDoseFormModal from './PRNDoseFormModal'

function PRNConsumptions({
  patientId,
  dateRange,
  marApprovals,
  isApprovalsLoading,
  statusMessageRef,
}) {
  const [selectedConsumptions, setSelectedConsumptions] = useState(null)
  const [isPRNDialogVisible, seIsPRNDialogVisible] = useState(false)
  const [selectedDose, setSelectedDose] = useState({})
  const [selectedDoseSig, setSelectedDoseSig] = useState({})
  const [selectedCellTime, setSelectedCellTime] = useState(null)
  const [dialogMode, setDialogMode] = useState('add')
  const [consumptionToBeUpdated, setConsumptionToBeUpdated] = useState({})
  const [dateHovered, setDateHovered] = useState()

  const { timezone } = useCurrentOrganization()
  const { role: currentUserRole } = useCurrentUser()
  const isAdmin = currentUserRole === 'site_admin'
  const dateMarApprovals = dateHovered ? marApprovals.filter((mar) => mar.days.includes(dateHovered.format('YYYY-MM-DD'))) : []

  const overlayRef = useRef(null)
  const dateOverlayRef = useRef(null)
  const {
    data: marDashboard,
    isLoading: isMarDashboardLoading,
  } = useMarDashboardQuery(patientId, dateRange, 'prn', statusMessageRef)

  const doseTemplate = (marTimeRow) => (
    <DoseDetails marTimeRow={marTimeRow} />
  )

  const detailsTemplate = (marTimeRow) => {
    const { doseSig } = marTimeRow
    return (
      <MarSigDetails
        doseSig={doseSig}
      />
    )
  }

  const openPRNDialog = (doseSig, cellTime) => {
    setSelectedDose(doseSig.dose)
    setSelectedCellTime(cellTime)
    setSelectedDoseSig(doseSig)
    setDialogMode('add')
    seIsPRNDialogVisible(true)
  }

  const handleEdit = (e, consumption) => {
    overlayRef.current.toggle(e)
    setDialogMode('edit')
    setConsumptionToBeUpdated({
      ...consumption,
      currentConfirmAt: buildDateAndTimeWithTimeZone({
        date: consumption.confirmedAt,
        time: consumption.confirmedAt,
        timezone,
      }),
    })
    setSelectedConsumptions(null)
    seIsPRNDialogVisible(true)
  }

  const showDateOverlay = (date, e) => {
    setDateHovered(date)
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
      requestAnimationFrame(() => {
        dateOverlayRef.current.show(e)
      })
    } else {
      dateOverlayRef.current.show(e)
    }
  }

  const hideDateOverlay = () => {
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
    }
  }

  const headerTemplate = (date, formattedDate, approvals) => (
    <AdherenceTableHeader
      date={date}
      formattedDate={formattedDate}
      approvals={approvals}
      showDateOverlay={showDateOverlay}
      hideDateOverlay={hideDateOverlay}
    />
  )

  const consumptionTemplate = useCallback((marTimeRow, column) => {
    const consumptions = marTimeRow.consumptionsByDate[column.field]
    const cellTime = moment(`${column.field} ${marTimeRow.time}`, 'YYYY-MM-DD HH:mm A').tz(timezone, true)
    const handleOnClickStatus = (e) => {
      setSelectedConsumptions(consumptions)
      setSelectedCellTime(cellTime)
      overlayRef.current.toggle(e)
    }
    return (
      <StatusIndicator
        consumptions={consumptions || []}
        doseSig={marTimeRow.doseSig}
        cellTime={cellTime}
        timezone={timezone}
        isAdmin={isAdmin}
        onEmptyCellClick={openPRNDialog}
        onConsumptionsClick={handleOnClickStatus}
      />
    )
  }, [openPRNDialog])

  const columns = marColumns(
    {
      dateRange,
      marApprovals,
      doseTemplate,
      doseSigTemplate: detailsTemplate,
      headerTemplate,
      consumptionTemplate,
      showTime: false,
    },
  )

  return (
    <>
      <div className="flex flex-column gap-3 text-base text-900 overflow-x-auto" style={{ overflowX: 'auto' }}>
        <DateTable
          data={marDashboard?.marTimeRows || []}
          columns={columns}
          isLoading={isMarDashboardLoading}
          tableClassName="dose-consumptions"
          emptyMessage="No medications available"
          className="schedule-mar-view"
          sortField="medicine"
          rowGroupMode="rowspan"
          groupRowsBy={['doseSig.dose.id', 'doseSig.id']}
          sortMode="single"
        />
        <MARApprovalsTable
          marApprovals={marApprovals}
          isLoading={isApprovalsLoading}
        />
      </div>
      <OverlayPanel ref={overlayRef} className="dose-overlay" dismissable>
        <PRNStatusOverlay
          timezone={timezone}
          consumptions={selectedConsumptions}
          {...(isAdmin && { handleEdit })}
        />
      </OverlayPanel>
      <OverlayPanel ref={dateOverlayRef} className="date-overlay">
        <ApprovalOverlay marApprovals={dateMarApprovals} />
      </OverlayPanel>
      {isPRNDialogVisible && (
        <PRNDoseFormModal
          visible={isPRNDialogVisible}
          onHide={seIsPRNDialogVisible}
          dose={selectedDose}
          doseSig={selectedDoseSig}
          selectedCellTime={selectedCellTime}
          patientId={patientId}
          mode={dialogMode}
          consumptionToBeUpdated={consumptionToBeUpdated}
          setConsumptionToBeUpdated={setConsumptionToBeUpdated}
        />
      )}
    </>
  )
}

export default PRNConsumptions

import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { useDiscontinueTreatment, useReactivateTreatment } from '@hooks/treatments'

export function TreatmentActionMenu({ statusMessage, treatment }) {
  const actionMenu = useRef(null)
  const navigate = useNavigate()

  const {
    mutateAsync: discontinueTreatment,
  } = useDiscontinueTreatment({ statusMessage })

  const {
    mutateAsync: reactivateTreatment,
  } = useReactivateTreatment({ statusMessage })

  if (!treatment) {
    return null
  }

  const taskActions = []

  if (!treatment.showInMedpass && treatment.tasks.length === 0) {
    taskActions.push({
      label: 'Create Task',
      icon: 'pi pi-stopwatch',
      command: () => navigate('create-task'),
    })
  }

  if (treatment.discontinuedAt) {
    taskActions.push({
      label: 'Reactivate',
      icon: 'pi pi-play',
      command: () => reactivateTreatment(treatment),
    })
  } else {
    taskActions.push({
      label: 'Discontinue',
      icon: 'pi pi-ban',
      command: () => discontinueTreatment(treatment),
    })
  }

  return (
    <div className="ml-auto">
      <Menu model={taskActions} popup ref={actionMenu} id="popup_menu" />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        className="p-button-sm"
        iconPos="right"
        aria-controls="popup_menu"
        aria-haspopup
        onClick={(event) => actionMenu.current.toggle(event)}
      />
    </div>
  )
}

export default TreatmentActionMenu

import moment from 'moment'
import { enumerateDaysBetweenMoments, initials } from '@services/utils'
import { momentFormats } from '@services/utils/moment'
import { statusLabels } from './adherenceDashboardConfig'

export const refusedReasonArray = (patientLabel) => [
  { value: 'PATIENT_REFUSED', label: `${patientLabel} refused` },
  { value: 'COULD_NOT_LOCATE', label: 'Could not locate' },
  { value: 'CONDITION_CHECK', label: 'Doesn\'t meet condition check' },
]

export const createStatusArray = [
  { value: 'CREATE_CONFIRMED', label: 'Given', disabled: false },
  { value: 'CREATE_MISSED', label: 'Missed', disabled: false },
  { value: 'CREATE_REFUSED', label: 'Refused', disabled: false },
]

export const overrideStatusArray = [
  { value: 'OVERRIDE_CONFIRMED', label: 'Given', disabled: false },
  { value: 'OVERRIDE_MISSED', label: 'Missed', disabled: false },
  { value: 'OVERRIDE_REFUSED', label: 'Refused', disabled: false },
]

export const getUserInitialsByFullName = (fullName) => {
  if (!fullName) return '??'

  return initials(fullName)
}

export const shouldShowAdministeredBy = (status) => ['CREATE_REFUSED', 'OVERRIDE_REFUSED', 'CREATE_CONFIRMED', 'OVERRIDE_CONFIRMED'].includes(status)

export const unknownStatusConsumptions = (consumptions) => {
  const consumptionsWithUnknownStatus = []
  const unknownStatus = []
  consumptions.forEach((consumption) => {
    let hasUnknownStatus = false
    consumption.administrationEvents.forEach((event) => {
      const isUnknown = statusLabels[event.code] === undefined
      if (isUnknown && !unknownStatus.includes(event.code)) {
        hasUnknownStatus = true
        unknownStatus.push(event.code)
      }
    })
    if (hasUnknownStatus) consumptionsWithUnknownStatus.push(consumption.id)
  })

  return { consumptionsWithUnknownStatus, unknownStatus }
}

export function marColumns(
  {
    dateRange,
    marApprovals,
    doseTemplate,
    doseSigTemplate,
    consumptionTemplate,
    headerTemplate,
    showTime = true,
  },
) {
  const days = enumerateDaysBetweenMoments(dateRange.startDate, dateRange.endDate)
  const columns = [
    {
      field: 'doseSig.dose.id',
      header: 'Medicine',
      body: doseTemplate,
      style: { minWidth: '18rem', width: '18rem', maxWidth: '18rem' },
    },
    {
      field: 'doseSig.id',
      header: 'Details',
      body: doseSigTemplate,
      style: { minWidth: '20rem', width: '20rem', maxWidth: '20rem' },
    },
  ]

  if (showTime) {
    columns.push({
      field: 'time',
      header: 'Time',
      body: (rowData) => rowData.time?.toUpperCase(),
      style: { minWidth: '8rem', width: '8rem', maxWidth: '8rem' },
    })
  }

  const dayCount = dateRange.endDate.diff(dateRange.startDate, 'days') + 1

  days.forEach((day) => {
    const dateIter = day.clone()
    // In the MAR view, if we show the weeklo or dayly view we show the day and the month
    // then for the monthly view we show the day only to have smaller columns
    const formattedDate = moment(dateIter).format(dayCount > 7 ? 'D' : 'MMM Do')

    let dayMarApprovals = []
    if (marApprovals) {
      const dateIterStr = dateIter.format('YYYY-MM-DD')
      dayMarApprovals = marApprovals.filter((mar) => mar.days.includes(dateIterStr))
    }

    columns.push({
      field: `${day.format(momentFormats.basicDate)}`,
      header: headerTemplate(dateIter, formattedDate, dayMarApprovals),
      body: consumptionTemplate,
      style: { minWidth: '10rem', width: '10rem', maxWidth: '10rem' },
    })
  })

  return columns
}

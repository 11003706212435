import React from 'react'
import moment from 'moment-timezone'
import { momentFormats } from '@services/utils/moment'
import { Divider } from 'primereact/divider'
import { isEmpty } from 'lodash'
import ColoredCircle from '../ColoredCircle'

function OccurrenceDetails({ occurrence, timezone }) {
  const {
    actionedBy, actionedAt, expectedAt, status,
  } = occurrence
  return (
    <div className="flex flex-column row-gap-2 m-1">
      {
        status && (
          <div className="flex flex-row gap-1 align-items-center text-left">
            <ColoredCircle type={status} />
            <span className="text-base font-medium line-height-2">
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
          </div>
        )
      }
      { expectedAt && (
      <div className="flex flex-row gap-1">
        <span className="text-xs">Expected:</span>
        <span className="text-xs font-medium">{ moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
      </div>
      )}
      { actionedAt && (
      <div className="flex flex-column gap-2">
        <div className="flex flex-row gap-1">
          <span className="text-xs">Completed:</span>
          <span className="text-xs font-medium">{ moment(actionedAt).tz(timezone).format(momentFormats.dateYearTime) }</span>
          <span className="text-xs">by</span>
          <span className="text-xs font-medium">{actionedBy?.fullName || 'Unspecified'}</span>
        </div>
        { occurrence.note
          && (
            <span className="text-xs">
              Note:
              {' '}
              {occurrence.note}
            </span>
          )}
      </div>
      )}
    </div>
  )
}

/**
 * Overlay that displays the status of task occurrences.
 *
 * @param {Object} occurrence - The selected cell containing details.
 * @param {string} timezone - The timezone of the organization.
 * @returns {JSX.Element} - JSX element representing the overlay.
 */
function StatusOverlay({
  occurrences,
  timezone,
}) {
  if (isEmpty(occurrences)) return null

  return (
    <div className="w-22rem flex flex-column">
      {
        occurrences.map((occurrence) => (
          <OccurrenceDetails
            key={occurrence.id}
            occurrence={occurrence}
            timezone={timezone}
          />
        )).reduce((prev, curr) => [prev, <Divider />, curr])
      }
    </div>
  )
}

export default StatusOverlay
